<template>
  <div class="element-div">
    <div
      class="flex card-content-item attr-box"
      v-for="(ele, index) in elementData.list"
      :key="index"
    >
      <div class="flex prop-box">
        <div class="text_hidden propname">
          {{ ele.name }}
        </div>

        <div class="flex propvalue">
          <el-select
            v-if="ele.type == 'select'"
            v-model="ele.value"
            placeholder="请选择"
            @change="entryInput(ele)"
          >
            <el-option
              v-for="(item, idx) in ele.list"
              :key="idx"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <div
            class="input-item-box"
            v-else
            @dblclick="onShowInput(ele)"
            :class="{ p15: !ele.showInput || ele.disable }"
          >
            <template v-if="ele.disable">
              {{ ele.value }}
            </template>
            <el-input
              v-else-if="ele.showInput && !ele.disable"
              v-model="ele.value"
              placeholder="请输入内容"
              :disabled="ele.disable"
              :readonly="ele.disable"
              ref="input"
              @keyup.enter.native="entryInput(ele)"
              @blur="() => entryInput(ele)"
              @input="ele.value = checkGreaterZero(ele.value, ele.typeName)"
            ></el-input>
            <template v-else>
              {{ ele.value }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LTArc",
  props: {
    pixiMap: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    featureDatas: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    }
  },
  components: {},
  data() {
    return {
      elementData: this.resetData()
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    // 数据赋值
    initData(datas) {
      console.log("initData", datas);
      if (datas.length == 1) {
        let it_geometry = datas[0];
        this.elementData.list.map(it => {
          it.value = this.$returnTypeName(it.sname, it_geometry);
          if (it.typeName && it.typeName == "number" && it.value)
            it.value = Number(it.value).toFixed(3);
          return it;
        });
      } else {
        this.elementData.list.map(it => {
          let list = [],
            isMore = false;
          datas.some(item => {
            list.push(this.$returnTypeName(it.sname, item));
            if (it.typeName && it.typeName == "number" && it.value)
              list.push(Number(it.value).toFixed(3));
            if (list.length >= 2 && Array.from(new Set(list)).length > 1) {
              it.value = "多种";
              isMore = true;
              return true;
            }
          });
          if (!isMore) it.value = list[0];
          return it;
        });
      }
    },
    // 重置数据
    resetData() {
      return {
        name: "弧",
        sname: "LTArc",
        type: "moreInput",
        show: false,
        value: "",
        list: [
          {
            name: "中心点x坐标",
            value: "",
            sname: "center.x",
            type: "input",
            typeName: "number"
          },
          {
            name: "中心点y坐标",
            value: "",
            sname: "center.y",
            type: "input",
            typeName: "number"
          },
          {
            name: "半径",
            value: "",
            sname: "radius",
            type: "input",
            typeName: "number"
          },
          {
            name: "起始角度",
            value: "",
            sname: "_start_angle",
            type: "input",
            typeName: "number"
          },
          {
            name: "结束角度",
            value: "",
            sname: "_end_angle",
            type: "input",
            typeName: "number"
          },
          {
            name: "弧长",
            value: "",
            sname: "_arcLength",
            type: "input",
            disable: true,
            typeName: "number"
          }
        ]
      };
    },
    // 规定typeName为number表单只能输入数字、小数点和负号
    checkGreaterZero(value, type) {
      if (type == "number") value = value.replace(/[^\-?\d.]/g, "");
      return value;
    },
    // 双击显示表单
    onShowInput(item) {
      if (item && item.disable) return;
      if (!item.showInput) {
        this.$set(item, "showInput", true);
        this.$nextTick(() => {
          this.$refs.input[0].focus();
        });
      } else item.showInput = false;
    },
    // 表单entry
    entryInput(ele) {
      this.$emit("entryInput", ele);
    }
  },
  watch: {
    featureDatas: {
      handler(newVal, oldVal) {
        this.elementData = this.resetData();
        if (newVal && newVal.length > 0 && oldVal != newVal) {
          this.initData(newVal);
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'

.element-div
  position relative
  width 100%
  height 100%
</style>
